import { Grid, Typography } from "@material-ui/core";

function Home() {
  return (
  
  <Grid container spacing={3} justifyContent="center">
      <Grid item>
          <p className="heading">Merle Beagles Are Real</p>
      </Grid>


  </Grid>

  )
}

export default Home;
