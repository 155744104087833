import "./App.css";
import React from 'react';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { Typography } from "@material-ui/core";

import Home from "./screens/Home";


function App() {
  return (
    <div className="App">
      <Home />
    </div>
  );
}

export default App;
